import React,{useState, useEffect, Suspense} from 'react';
import { BrowserRouter as Router, Route, Routes, useParams, useNavigate} from 'react-router-dom';
import { Box } from '@mui/material'
import Registration from '../../pages/registration/Registration'
import RealTimePrice from '../../pages/real-time-price/RealTimePrice'
import RentOnline from '../../pages/rent-online/RentOnline';
import FinalSuccess from '../../pages/success/FinalSuccess';
import RouteGuard from './RouteGuard';
import NotAllowed from './NotAllowed';
import Maintenance from './Maintenance';
import CircularLoding from './CircularLoading';
import QuoteRedirect from '../redirect-components/QuoteRedirect';
import PaynowRedirect from '../redirect-components/PaynowRedirect';
import { toast } from 'react-toastify';



function MainContent() {
    const [labels, setLabels] = useState(null);
    const [isLoadingLabels, setIsLoadingLabels] = useState(true);
    useEffect(()=>{
      const language = process.env.REACT_APP_LANGUAGE;
      const loadLabels = async () => {
        try{
          if(language === "english"){
            const enLabels = await import('../../assets/languages/en-labels.json')
            setLabels(enLabels.default);
          }
          else if (language === 'spanish'){
            const esLabels = await import('../../assets/languages/es-labels.json')
            setLabels(esLabels.default);
          }
          else{
            toast.error("Unsupported language set in environment.")
          }
        }
        catch(error){
          toast.error("Error loading the labels")
        }finally{
          setIsLoadingLabels(false);
        }
      }

      loadLabels();
    },[]);
    const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === '1'; // if this value is 1, the app will render maintenance component when requested to the site domain else if the value is 0, the app will render actuall components
    const [orderComplete, setOrderComplete] = useState(false); // used to keep track of order completion when order is completed, this value will become true
    // function to handle the order completion. it is passed down to components where the order completion state has to be handled appropriately
    const [isRedirected, setIsRedirected] = useState(false);
    const handleOrderComplete = (val) =>{
        setOrderComplete(val)
    }
    if(isLoadingLabels){
      return <CircularLoding/>
    }
    return (
        
        <Router>
            <Box component={'main'} minHeight={{
                xs: 'calc(100vh - 235px)', sm: 'calc(100vh - 202px)',
                md: 'calc(100vh - 216px)'
            }} >
                <Suspense fallback={<CircularLoding/>}>
                  {isMaintenanceMode ? (
                      <Routes>
                          <Route path="*" element={<Maintenance />} />
                      </Routes>
                  ):(
                      <Routes>
                      <Route path='/' element={<Registration handleOrderComplete={handleOrderComplete} label={labels.screen1}/>}></Route>
                      <Route path='/bigbox-quote' element={<RouteGuard component={RealTimePrice} orderComplete={orderComplete} isRedirected={isRedirected} label={labels.screen2}/>}></Route>
                      <Route path='/rent-online' element={<RouteGuard component={RentOnline} orderComplete={orderComplete} label={labels.screen3}/>}></Route>
                      <Route path='/order-success' element={<RouteGuard component={FinalSuccess} handleOrderComplete={handleOrderComplete} />}></Route>
                      {/* <Route path='/quote/:quote_id' element={<QuoteRedirect setIsRedirected={setIsRedirected}/>} ></Route> New Route */}
                      <Route path='/quote/:quote_token' element={<QuoteRedirect setIsRedirected={setIsRedirected} />} ></Route>
                      <Route path='/pay-now/:quote_id' element={<PaynowRedirect/>} ></Route>
                      <Route path='/not-allowed' element={<NotAllowed />}></Route>
                      </Routes>
                    )
                  }
                </Suspense>
            </Box>
        </Router>
    )
}

export default MainContent