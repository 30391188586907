import React, { useState, useEffect } from 'react';
import { Typography, Box, Container, Stack } from '@mui/material'
import Logo from '../logo/Logo'
import Contact from '../contact/Contact'
import ContactSm from '../contact/ContactSm'

function Header() {
    const [HeaderLabel, setHeaderLabel] = useState('Get Rates Below');
    return (
        <>
            <Typography component={'header'} position={'sticky'} top={0} zIndex={1500} boxShadow={1}>
                <Box sx={{ backgroundColor: 'primary.main', display: { xs: 'none', md: 'block' }, minHeight: { xs: '0px', md: '40px' } }} >
                </Box>
                <Box py={{ xs: 1, sm: 4 }} sx={{ backgroundColor: 'common.white' }}>
                    <Container fixed>
                        <Box position={'relative'} display={{ xs: 'flex', md: 'flex' }} gap={1}>
                            <Box position={{ xs: 'static', md: 'absolute' }} top={{ xs: '0', md: '-50px' }}>
                                <a href='https://bigbox.com' onClick={() => { sessionStorage.clear() }}><Logo /></a>
                            </Box>
                            <Stack flex={1} display={'flex'} direction='row' justifyContent={{ xs: 'center', md: 'end' }} alignItems={'center'}>
                                <Contact label={HeaderLabel} />
                                <ContactSm label={HeaderLabel} />
                            </Stack>
                        </Box>
                    </Container>
                </Box>
            </Typography >
        </>
    )
}

export default Header
