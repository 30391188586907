import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';

function QuoteComponent({ data, loading }) {
    return (
        <>
            <Box
                sx={{
                    padding: '12px',
                    border: '1px solid #77BCFF',
                    backgroundColor: '#EAF5FF',
                    borderRadius: '6px',
                    marginTop: '24px',
                    marginBottom: '24px',
                }}
            >
                {loading ? <Typography style={{ justifyContent: 'center', display: 'flex' }}><CircularProgress /></Typography> :
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant='h5'>Item</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h5' style={{ textAlign: 'right' }}>Price</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h5' style={{ textAlign: 'center' }}>Qty</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant='h5' style={{ textAlign: 'right' }}>Total</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {data.nofmon == 1 || data.initialDeliveryFee > 99.00 ?
                                    <TableRow>
                                        <TableCell>
                                            <Typography>Initial Delivery Fee</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{ textAlign: 'right' }}>
                                                ${parseFloat(data.initialDeliveryFee) === 0 ? 99.00 : data.initialDeliveryFee}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{ textAlign: 'center' }}>1</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{ textAlign: 'right' }}>
                                                ${parseFloat(data.initialDeliveryFee) === 0 ? 99.00 : data.initialDeliveryFee}
                                            </Typography>
                                        </TableCell>
                                    </TableRow> : <TableRow />
                                }


                                {data.nofmon > 1 && data.initialDeliveryDiscount > data.initialDeliveryFee ?
                                    <TableRow>
                                        <TableCell>
                                            <Typography>Initial Delivery Fee Discount</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                style={{ textAlign: 'right' }}>(${data.initialDeliveryDiscount})</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                style={{ textAlign: 'center' }}> 1 </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                style={{ textAlign: 'right' }}>(${data.initialDeliveryDiscount})</Typography>
                                        </TableCell>
                                    </TableRow> : <TableRow />
                                }

                                <TableRow>
                                    <TableCell>
                                        <Typography>Monthly Rent</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography style={{ textAlign: 'right' }}>${(data.monthlyRent / data.nofbox).toFixed(2)}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography style={{ textAlign: 'center' }}>{data.nofbox}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography style={{ textAlign: 'right' }}>${data.monthlyRent}</Typography>
                                    </TableCell>
                                </TableRow>
                                {data.monthlyProtectionPlan > 0 ?
                                    <TableRow>
                                        <TableCell>
                                            <Typography>Monthly Protection Plan</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                style={{ textAlign: 'right' }}>${data.monthlyProtectionPlan > 0 ? (data.monthlyProtectionPlan / data.nofbox).toFixed(2) : "0.00"}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                style={{ textAlign: 'center' }}> {data.nofbox} </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{ textAlign: 'right' }}>${data.monthlyProtectionPlan}</Typography>
                                        </TableCell>
                                    </TableRow> : <TableRow />
                                }
                                {data.nofpad > 0 ?
                                    <TableRow>
                                        <TableCell>
                                            <Typography>Lock</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                style={{ textAlign: 'right' }}>${data.nofpad > 0 ? Number(data.lock).toFixed(2) : "0.00"} </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{ textAlign: 'center' }}>{data.nofpad}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography
                                                style={{ textAlign: 'right' }}>${(data.lock * data.nofpad).toFixed(2)}</Typography>
                                        </TableCell>
                                    </TableRow> : <TableRow />
                                }
                               {Number(sessionStorage.getItem('promo_value') || 0) > 0 ? (
                                    <TableRow>
                                        <TableCell>
                                            <Typography>Promotion Discount</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{ textAlign: 'right' }}>(${sessionStorage.getItem('promo_value') || 0})</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{ textAlign: 'center' }}></Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography style={{ textAlign: 'right' }}>(${sessionStorage.getItem('promo_value') || 0})</Typography>
                                        </TableCell>
                                    </TableRow>
                                ) : null}
                                <TableRow>
                                    <TableCell colSpan={3} style={{ borderTop: '1px solid #E4E4E4', textAlign: 'left' }}>
                                        <Typography>Sub Total</Typography>
                                    </TableCell>
                                    <TableCell style={{ borderTop: '1px solid #E4E4E4', textAlign: 'right' }}>
                                        <Typography style={{ textAlign: 'right' }}>${(Number(data.subtotal)).toFixed(2)}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={3} style={{ textAlign: 'left' }}>
                                        <Typography>Tax</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography style={{ textAlign: 'right' }}>${data.tax}</Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        colSpan={3}
                                    >
                                        <Typography
                                            style={{
                                                fontWeight: 700,
                                                fontSize: '20px',
                                                textAlign: 'left',
                                                borderTop: '1px solid #E4E4E4',
                                                color: '#0072BD',
                                                padding: '16px 4px 4px 4px'
                                            }}
                                        >
                                            Your Quote - Total due Today
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                    >
                                        <Typography
                                            style={{
                                                fontWeight: 700,
                                                fontSize: '20px',
                                                textAlign: 'right',
                                                borderTop: '1px solid #E4E4E4',
                                                color: '#0072BD',
                                                padding: '16px 4px 4px 4px'
                                            }}
                                        >
                                            ${data.total}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>}
            </Box>
        </>
    )
}

export default QuoteComponent;
